<template lang="en">
    <div class="CAButton">
        <slot>

        </slot>
        
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="">
    
</style>