<template>
    <div class="error">
    <div class="workingcontent">
        <h1>You missed the Universe <br><b>(ERROR 404)</b></h1>
        <p>
            This page is not avalible. <br>
            Please go back to the <router-link :to="'/'">Homepage</router-link> <br>or follow me at Social Media 😎
        </p>

        <div class="social_media">
            <a href="https://www.artstation.com/marcusharting" target="_blank"><img src="../assets/artstation.svg" alt="Artstation"></a>
            <a href="https://www.instagram.com/marcusunivers/" target="_blank"><img src="../assets/insta.svg" alt="Instagram"></a>
            <a href="https://youtube.com/@marcusunivers" target="_blank"><img src="../assets/yt.svg" alt="YouTube"></a>
        </div>
    </div>
</div>    
</template>


<style lang="sass">
        

</style>
